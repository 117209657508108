import http from './http';
import i18n from '@/i18n/i18n-config';
import getFormData from '@/util/form-data';
import clone from 'just-clone';

const endpoint = 'api/inventory-items';

function getInventoryItemForBackend(inventoryItem) {
  const item = clone(inventoryItem);
  delete item.inventory_item_group;
  delete item.order_part_materials;
  delete item.purchases;
  delete item.order_parts;
  return item;
}

const inventoryItemService = {
  model: 'inventoryItem',

  getPage: (page, params) => http.get(endpoint, { params: { ...params, page } }),

  getById: id => http.get(`${endpoint}/${id}`),

  getLastPurchaseEmailBody: async id => {
    const res = await http.get(`${endpoint}/${id}/get-last-purchase-supplier-inquiry-email-body`);
    res.data.template = res.data.template.replaceAll('\\n', '\n');
    return res;
  },

  search: query => http.get(`${endpoint}/find/${query}`),

  create: inventoryItem =>
    http.post(`${endpoint}`, getFormData(getInventoryItemForBackend(inventoryItem))),

  update: inventoryItem => {
    const payload = getFormData(getInventoryItemForBackend(inventoryItem));
    payload.append('_method', 'PUT');
    return http.post(`${endpoint}/${inventoryItem.id}`, payload);
  },

  delete: inventoryItem => http.delete(`${endpoint}/${inventoryItem.id}`),
};

export default inventoryItemService;

export const INVENTORY_ITEM_TYPES = [
  {
    text: i18n.t('inventory_item_types.manufactured'),
    value: 'manufactured',
  },
  {
    text: i18n.t('inventory_item_types.bought'),
    value: 'bought',
  },
];

export const getDefaultInventoryItem = () => ({
  type: 'manufactured',
});

// import { roleGuard } from '@/router/guards';
// import store from '@/store';

export const invoiceRoutes = [
  // {
  //   path: '/invoices',
  //   name: 'invoices',
  //   component: () => import(/* webpackChunkName: "invoices" */ '@/views/invoices/Invoices'),
  //   beforeEnter: roleGuard,
  //   meta: { allowedRoles: ['admin'] },
  //   children: [
  //     {
  //       path: 'create',
  //       name: 'createInvoice',
  //       component: () =>
  //         import(/* webpackChunkName: "createInvoice" */ '@/views/invoices/CreateInvoice'),
  //       beforeEnter: roleGuard,
  //       meta: {
  //         allowedRoles: ['admin'],
  //         getBackRoute: () => ({
  //           name: 'invoices',
  //           query: store.state.invoices.invoiceFilterParams,
  //         }),
  //       },
  //     },
  //     {
  //       path: 'update-counter',
  //       name: 'updateInvoiceCounter',
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "createInvoice" */ '@/views/invoices/EditInvoiceNumberCounter'
  //         ),
  //       beforeEnter: roleGuard,
  //       meta: {
  //         allowedRoles: ['admin'],
  //         getBackRoute: () => ({
  //           name: 'invoices',
  //           query: store.state.invoices.invoiceFilterParams,
  //         }),
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: '/invoices/:invoiceId',
  //   name: 'invoice',
  //   component: () => import(/* webpackChunkName: "invoice" */ '@/views/invoices/Invoice'),
  //   beforeEnter: roleGuard,
  //   meta: {
  //     allowedRoles: ['admin'],
  //     getBackRoute: () => ({
  //       name: 'invoices',
  //       query: store.state.invoices.invoiceFilterParams,
  //     }),
  //   },
  //   children: [
  //     {
  //       path: 'edit',
  //       name: 'editInvoice',
  //       component: () =>
  //         import(/* webpackChunkName: "editInvoice" */ '@/views/invoices/EditInvoice'),
  //       beforeEnter: roleGuard,
  //       meta: {
  //         allowedRoles: ['admin'],
  //         getBackRoute: () => ({
  //           name: 'invoice',
  //         }),
  //       },
  //     },
  //     {
  //       path: 'send',
  //       name: 'sendInvoice',
  //       component: () =>
  //         import(/* webpackChunkName: "sendInvoice" */ '@/views/invoices/SendInvoice'),
  //       beforeEnter: roleGuard,
  //       meta: {
  //         allowedRoles: ['admin'],
  //         getBackRoute: () => ({
  //           name: 'invoice',
  //         }),
  //       },
  //     },
  //   ],
  // },
];

export function parseArrayQueryParam(params, key, itemType = 'number') {
  if (!params[key]) {
    return params;
  }
  const param = params[key];
  let array = typeof param === 'string' ? [param] : [...param];
  if (array && itemType === 'number') {
    array = array.map(s => +s);
  }
  return { ...params, [key]: array };
}

export function isUrl(string) {
  // https://gist.github.com/dperini/729294, https://mathiasbynens.be/demo/url-regex
  const regex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

  return string.match(regex);
}

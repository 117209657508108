/* eslint-disable no-shadow,no-param-reassign,global-require,import/no-unresolved, */
import {
  SET_SETTINGS, SET_TOUCH_DEVICE, UPDATE_LOCALE, UPDATE_SETTING,
} from '../mutation-types';

const state = {
  locale: 'lt',
  localeIcon: require('@/assets/locale/lt-flag.png'), // 'require' is used to make v-img work with dynamic src attributes
  isTouchDevice: false,
  sidenavOpen: false,
};

const mutations = {
  [SET_SETTINGS](state, payload) {
    state.sidenavOpen = payload.sidenavOpen === false ? payload.sidenavOpen : true;
    state.locale = payload.locale ? payload.locale : 'lt';
    state.localeIcon = payload.localeIcon ? payload.localeIcon : require('@/assets/locale/lt-flag.png');
    localStorage.setItem('settings', JSON.stringify(state));
  },

  [UPDATE_SETTING](state, payload) {
    if (payload) {
      state[payload.key] = payload.value;
    }
    localStorage.setItem('settings', JSON.stringify(state));
  },

  [UPDATE_LOCALE](state, payload) {
    state.locale = payload.locale;
    state.localeIcon = payload.localeIcon;
    localStorage.setItem('settings', JSON.stringify(state));
  },

  [SET_TOUCH_DEVICE](state) {
    state.isTouchDevice = true;
  },
};

const settings = {
  state,
  mutations,
};

export default settings;

/* eslint-disable import/no-cycle */
import store from '../store/index';

function loginGuard(to, from, next) {
  if (store.getters.currentUser) {
    return next(from.path);
  }
  return next();
}

function authGuard(to, from, next) {
  if (!store.getters.currentUser) {
    return next(from.path === '/' ? '/login' : false);
  }
  return next();
}

// To use this guard, specify requiredPermission in route meta.
// e.g. meta: { requiredPermission: 'can_view_clients' },
function permissionsGuard(to, from, next) {
  if (!store.getters.currentUser) {
    return next(from.path === '/' ? '/login' : false);
  }

  if (store.getters.currentUser.permissions[to.meta.requiredPermission]) {
    return next();
  }
  return next(from.path);
}

// To use this guard, specify allowedRoles in route meta.
// e.g. meta: { allowedRoles: ['admin', 'manager'] },
async function roleGuard(to, from, next) {
  if (!to.meta.allowedRoles || !store.getters.currentUser) {
    return next('/login');
  }

  if (to.meta.allowedRoles.indexOf(store.getters.currentUser.role) > -1) {
    return next();
  }
  return next(from.path);
}

export { loginGuard, authGuard, permissionsGuard, roleGuard };

import http from './http';
import i18n from '../i18n/i18n-config';
import eventBus, { OPEN_SNACKBAR } from '@/util/event-bus';

const endpoint = 'api/order-parts';

const getOrderPartRequestBody = part => {
  const body = JSON.parse(JSON.stringify(part));
  for (let i = 0; i < body.materials?.length; i++) {
    if (typeof body.materials[i].id === 'string') {
      // key generated by uuidv4 to be used as :key in the table while id is absent
      delete body.materials[i].id;
    }
  }
  if (body.inventory_item?.type === 'bought') {
    return {
      title: body.title,
      order_id: body.order_id,
      inventory_item_id: body.inventory_item.id,
      quantity_in_warehouse: body.quantity_in_warehouse,
      quantity_issued: body.quantity_issued,
      warehouse_location_id: body.warehouse_location_id,
      processes: [],
    };
  }

  return body;
};

const orderPartService = {
  model: 'orderPart',

  getAll: () => http.get(`${endpoint}`),

  getById: (id, params) => http.get(`${endpoint}/${id}`, { params }),

  search: query => http.get(`${endpoint}/find/${query}`),

  create: orderPart => http.post(`${endpoint}`, getOrderPartRequestBody(orderPart)),

  update: orderPart => http.put(`${endpoint}/${orderPart.id}`, getOrderPartRequestBody(orderPart)),

  delete: orderPart => http.delete(`${endpoint}/${orderPart.id}`),

  updateLocation: orderPart => http.patch(`${endpoint}/${orderPart.id}`, orderPart),

  updateProcess: process =>
    http.patch(`api/order-part-processes/${process.id}`, process).catch(err => {
      if (
        err?.response?.status === 422 &&
        err.response.data?.errors?.status?.includes(
          'validation.can_complete_if_previous_processes_already_started',
        )
      ) {
        eventBus.$emit(OPEN_SNACKBAR, {
          text: i18n.t('can_complete_if_previous_processes_already_started'),
          timeout: 10000,
        });
      }
      return Promise.reject(err);
    }),

  getProcessProgress: processId => http.get(`api/order-part-processes/${processId}/progress`),

  getAllWorkerProcessProgress: processId =>
    http.get(`api/order-part-processes/${processId}/all-progress`),

  updateProcessProgress: (processId, payload) =>
    http.put(`api/order-part-processes/${processId}/progress`, payload),

  getProcessChangelog: processId => http.get(`api/order-part-processes/${processId}/change-log`),

  getEmployeeCompensation: (params, signal) =>
    http.get('api/employee-compensation', { params, signal }),

  getEmployeeCompensationReport: params =>
    http({
      url: 'api/employee-compensation/export/xlsx',
      method: 'GET',
      responseType: 'blob',
      params,
    }),
};

export default orderPartService;

export const ORDER_PART_STATUSES = [
  {
    text: i18n.t('order_part_statuses.not_started'),
    value: 'not_started',
    color: 'grey lighten-4',
  },
  {
    text: i18n.t('order_part_statuses.prepared'),
    value: 'prepared',
    color: 'yellow lighten-4',
  },
  {
    text: i18n.t('order_part_statuses.in_progress'),
    value: 'in_progress',
    color: 'blue lighten-4',
  },
  { text: i18n.t('order_part_statuses.completed'), value: 'completed', color: 'green accent-1' },
  { text: i18n.t('order_part_statuses.canceled'), value: 'canceled', color: 'grey lighten-2' },
];

export const getOrderPartStatusMap = () => {
  const map = {};
  ORDER_PART_STATUSES.forEach(status => {
    map[status.value] = { ...status };
  });
  return map;
};

export const ORDER_PART_PROCESS_STATUSES = [
  {
    text: i18n.t('not_started'),
    value: 'not_started',
    color: 'grey lighten-4',
    buttonText: 'start',
    buttonColor: 'success',
    nextStatus: 'in_progress',
    iconColor: 'grey',
    hexColor: '#F5F5F5',
  },
  {
    text: i18n.t('in_progress'),
    value: 'in_progress',
    color: 'blue lighten-4',
    buttonText: 'finish',
    buttonColor: 'error',
    nextStatus: 'completed',
    iconColor: 'blue',
    hexColor: '#BBDEFB',
  },
  {
    text: i18n.t('completed'),
    value: 'completed',
    color: 'green accent-1',
    buttonText: 'reset',
    nextStatus: 'not_started',
    iconColor: 'success',
    hexColor: '#B9F6CA',
  },
  {
    text: i18n.t('canceled'),
    value: 'canceled',
    color: 'grey lighten-2',
    buttonText: '',
    nextStatus: '',
    iconColor: 'grey',
    hexColor: '#E0E0E0',
  },
];

export const getOrderPartProcessStatusMap = () => {
  const map = {};
  ORDER_PART_PROCESS_STATUSES.forEach(status => {
    map[status.value] = { ...status };
  });
  return map;
};

export const ORDER_PART_DELIVERED_BY_TYPES = [
  {
    text: process.env.VUE_APP_CUSTOM_APP_TITLE || i18n.t('delivered_by_types.us'),
    value: 'us',
    color: 'grey lighten-4',
    hexColor: '#F5F5F5',
  },
  {
    text: i18n.t('delivered_by_types.supplier'),
    value: 'supplier',
    color: 'blue lighten-4',
    hexColor: '#BBDEFB',
  },
  {
    text: i18n.t('delivered_by_types.client'),
    value: 'client',
    color: 'green accent-1',
    hexColor: '#B9F6CA',
  },
  {
    text: i18n.t('delivered_by_types.other'),
    value: 'other',
    color: 'grey lighten-2',
    hexColor: '#E0E0E0',
  },
];

export const ORDER_PART_DOCUMENT_TYPES = [
  { value: 'material_certificate', text: i18n.t('order_part_document_types.material_certificate') },
  {
    value: 'declaration_of_conformity',
    text: i18n.t('order_part_document_types.declaration_of_conformity'),
  },
  { value: 'invoice', text: i18n.t('order_part_document_types.invoice') },
  { value: 'bill_of_lading', text: i18n.t('order_part_document_types.bill_of_lading') },
  { value: 'other', text: i18n.t('order_part_document_types.other') },
];

export const getOrderPartDocumentTypesMap = () => {
  const map = {};
  ORDER_PART_DOCUMENT_TYPES.forEach(type => {
    map[type.value] = { ...type };
  });
  return map;
};

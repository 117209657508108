import http from './http';

const endpoint = 'api/tags';

const tagService = {
  model: 'tag',

  getAll: (params = {}) => http.get(endpoint, { params }),
};

export default tagService;

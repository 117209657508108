/* eslint-disable import/prefer-default-export */
import Vue from 'vue';

export function mapErrorsToInputs(errors) {
  const mappedErrors = {};
  if (errors.response && errors.response.status === 422) {
    Object.keys(errors.response.data.errors).forEach(key => {
      mappedErrors[key] = errors.response.data.errors[key];
    });
  }
  return mappedErrors;
}

export function collectMultiSelectErrors(errors, field) {
  let aggregatedErrors = [];
  Object.keys(errors).forEach(key => {
    if (key.indexOf(field) > -1) {
      aggregatedErrors = aggregatedErrors[field]
        ? [...aggregatedErrors[field], ...errors[key]]
        : errors[key];
    }
  });
  return aggregatedErrors;
}

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else if (typeof data === 'boolean') {
    const value = data ? '1' : '0';
    formData.append(parentKey, value);
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  }
}

export function jsonToFormData(data, isPutEndpoint) {
  const formData = new FormData();

  buildFormData(formData, data);

  if (isPutEndpoint) {
    formData.append('_method', 'PUT');
  }

  return formData;
}

export function getRequiredFieldLabel(label) {
  return `${label}*`;
}

export function scrollToFirstError() {
  Vue.nextTick().then(() => {
    const el = document.querySelector('.v-input.error--text');
    if (el) {
      el.scrollIntoView();
    }
  });
}

import http from './http';
import i18n from '../i18n/i18n-config';

const endpoint = 'api/orders';

export const ORDER_STATUSES = [
  {
    text: i18n.t('not_started'),
    value: 'not_started',
    color: 'grey lighten-4',
    filterLabel: i18n.t('show_not_started'),
  },
  {
    text: i18n.t('in_progress'),
    value: 'in_progress',
    color: 'blue lighten-4',
    filterLabel: i18n.t('show_in_progress'),
  },
  {
    text: i18n.t('completed'),
    value: 'completed',
    color: 'green accent-1',
    filterLabel: i18n.t('show_completed'),
  },
  {
    text: i18n.t('canceled'),
    value: 'canceled',
    color: 'grey lighten-2',
    filterLabel: i18n.t('show_canceled'),
  },
];

export const getDefaultOrderFilterParams = () => ({
  status: ['not_started', 'in_progress'],
});

const orderService = {
  model: 'order',

  getPage: (page, params) => http.get(endpoint, { params: { ...params, page } }),

  getById: id => http.get(`${endpoint}/${id}`),

  getOrderNo: type => http.get(`${endpoint}/next-suggested-order-no?type=${type}`),

  getMaterialReportPage: (page, params) => {
    const transformedParams = { ...params };
    transformedParams.supplier_contact_id = params.supplier ? params.supplier.id : null;
    delete transformedParams.supplier;

    // transformedParams.client_contact_id = params.client ? params.client.id : null;
    // delete transformedParams.client;
    //
    // if (!transformedParams.only_low_stock) {
    //   delete transformedParams.only_low_stock;
    // }
    return http.get(`api/order-materials-report?page=${page}`, { params: transformedParams });
  },

  getSummary: orderPartId => http.get(`${endpoint}/summary-by-order-part/${orderPartId}`),

  search: query => http.get(`${endpoint}/find/${query}`),

  create: order => http.post(`${endpoint}`, order),

  update: order => http.put(`${endpoint}/${order.id}`, order),

  delete: order => http.delete(`${endpoint}/${order.id}`),

  reorder: (selectedOrder, newIndex) =>
    http.post(`${endpoint}/${selectedOrder.id}/reorder/${newIndex}`),

  clone: order => http.post(`${endpoint}/${order.id}/clone`),

  generateOffer: order => http.post(`${endpoint}/${order.id}/generate-offer`),
};

export default orderService;

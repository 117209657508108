<template>
  <span></span>
</template>

<script>
import { isUrl } from '@/util/url';
import { isFirefox, isSafari } from '@/util/browser-detection';

export default {
  name: 'QrScannerListener',

  props: {
    redirectFragments: {
      // only redirect to the scanned URL if it contains one of these strings
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      scannedValue: '',
      timerId: null,
    };
  },

  mounted() {
    window.addEventListener('keypress', this.handleScannerInput, false);
  },

  beforeDestroy() {
    window.removeEventListener('keypress', this.handleScannerInput);
  },

  methods: {
    handleScannerInput(event) {
      let { key } = event;

      if (key === 'Enter') {
        // scanner finished scanning
        clearTimeout(this.timerId);
        this.handleUrl(this.scannedValue);
        this.scannedValue = '';
        return;
      }

      clearTimeout(this.timerId);
      this.timerId = setTimeout(
        () => {
          // because the listener is global it will catch regular user input
          // since scanner scans really quickly followed by an "enter" key
          // this timer will go off only when the scanner is done scanning
          this.scannedValue = '';
        },
        isSafari() ? 1000 : 100, // Safari has weird delays when scanner is inputting longer strings
      );

      if (key === '/' && this.scannedValue.length > 3 && isFirefox()) {
        // prevents Firefox from opening quick find menu on '/' click while scanning
        event.preventDefault();
      }

      const replaceSymbolsMap = {
        ą: '1',
        č: '2',
        ę: '3',
        ė: '4',
        į: '5',
        š: '6',
        ų: '7',
        ū: '8',
        ž: '=',
      };

      if (replaceSymbolsMap[key]) {
        // when LT keyboard is selected, numbers in URL will be passed as LT letters
        key = replaceSymbolsMap[key];
      }

      if (key.length === 1) {
        // filters out shift, ctrl keys, etc.
        this.scannedValue += key;
      }
    },

    handleUrl(string) {
      console.log('scanned: ', string);
      const prefix = string.includes('http') ? '' : 'http://';
      if (!isUrl(`${prefix}${string}`)) {
        return;
      }

      let redirect = false;
      for (let i = 0; i < this.redirectFragments.length; i++) {
        if (string.includes(this.redirectFragments[i])) {
          redirect = true;
          break;
        }
      }

      if (redirect) {
        window.location.href = `${prefix}${string}`;
      }

      this.$emit('input', string);
    },
  },
};
</script>

<style scoped></style>

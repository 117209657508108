import http from './http';

const endpoint = 'api/tasks';

const taskService = {
  model: 'task',

  getAll: params => http.get(endpoint, { params }),

  getById: task => http.get(`${endpoint}/${task.id}`, task),

  create: task => http.post(`${endpoint}`, task),

  update: task => http.put(`${endpoint}/${task.id}`, task),

  updateTasksOrder: data => http.post(`${endpoint}/sort`, data),

  delete: task => http.delete(`${endpoint}/${task.id}`),
};

export default taskService;

export const updateItemById = (array, updatedItem) => {
  const { length } = array;
  for (let i = 0; i < length; i++) {
    if (array[i].id === updatedItem.id) {
      array.splice(i, 1, updatedItem);
      break;
    }
  }
  return array;
};

export const removeItemById = (array, id) => {
  const { length } = array;
  for (let i = 0; i < length; i++) {
    if (array[i].id === id) {
      array.splice(i, 1);
      break;
    }
  }
  return array;
};

export const arrayToMap = (array, key = 'value') => {
  const map = {};
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    map[item[key]] = item;
  }
  return map;
};

export function updateArrayItem(array, updatedItem, key = 'id') {
  return array.map(item => (item[key] === updatedItem[key] ? { ...item, ...updatedItem } : item));
}

export function removeArrayItem(array, deletedItem, key = 'id') {
  return array.filter(item => item[key] !== deletedItem[key]);
}

import Vue from 'vue';

// if request URL contains a string from this array - don't add it to pendingRequests
const blacklistedFragments = [];

function isUrlBlacklisted(url) {
  for (let i = 0; i < blacklistedFragments.length; i++) {
    if (url.indexOf(blacklistedFragments[i]) > -1) {
      return true;
    }
  }

  return false;
}

const state = {
  pendingRequests: {},
};

const getters = {
  // Use this to show progress indicators for API requests
  // e.g.: $store.getters.loading['get:api/users']
  loading(state) {
    const map = {};
    Object.keys(state.pendingRequests).forEach(key => {
      map[key] = true;
    });
    return map;
  },
};

const mutations = {
  STORE_PENDING_REQUEST(state, url) {
    const currentCount = state.pendingRequests[url] || 0;
    Vue.set(state.pendingRequests, url, currentCount + 1);
  },

  REMOVE_PENDING_REQUEST(state, url) {
    const currentCount = state.pendingRequests[url] || 0;
    if (currentCount - 1 <= 0) {
      Vue.delete(state.pendingRequests, url);
    } else {
      Vue.set(state.pendingRequests, url, currentCount - 1);
    }
  },
};

const actions = {
  setPendingRequest({ commit }, url) {
    if (url && !isUrlBlacklisted(url)) {
      commit('STORE_PENDING_REQUEST', url);
    }
  },

  removePendingRequest({ commit }, url) {
    if (url && !isUrlBlacklisted(url)) {
      commit('REMOVE_PENDING_REQUEST', url);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

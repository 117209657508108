import store from '../index';

export const OPEN_DIALOG = 'openDialog';
export const CLOSE_DIALOG = 'closeDialog';
export const CLOSE_LAST_DIALOG = 'closeLastDialog';

const state = {
  openDialogs: [],
};

const getters = {
  isDialogOpen: state => dialogName => state.openDialogs.indexOf(dialogName) > -1,
};

const mutations = {
  [OPEN_DIALOG](state, dialog) {
    if (state.openDialogs.indexOf(dialog) === -1) {
      state.openDialogs.push(dialog);
      window.history.pushState({ dialog }, '', '');
    }
  },

  [CLOSE_DIALOG](state, dialog) {
    for (let i = 0; i < state.openDialogs.length; i++) {
      if (state.openDialogs[i] === dialog) {
        state.openDialogs.splice(i, 1);
        break;
      }
    }
  },

  [CLOSE_LAST_DIALOG](state) {
    state.openDialogs.pop();
  },
};

window.onpopstate = () => {
  if (store.state.dialogs.openDialogs.length) {
    store.commit(CLOSE_LAST_DIALOG);
  }
};

export default {
  state,
  getters,
  mutations,
};

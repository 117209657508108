<template>
  <v-menu
    v-model="isMenuOpen"
    :close-on-content-click="false"
    class="overflow-y-hidden"
    offset-y
    left
    @input="onMenuToggle"
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" class="mx-3" icon>
        <v-badge
          :value="unreadNotificationCount > 0"
          :content="unreadNotificationCount"
          color="error"
          overlap
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card class="notifications-card">
      <v-card-title class="grey lighten-4 subtitle-1 py-1">
        {{ $t('notifications') }}
        <v-spacer />
        <v-tooltip bottom open-delay="500">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="markAllAsSeen">
              <v-icon>mdi-check-all</v-icon>
            </v-btn>
          </template>
          <span>
            {{ $t('mark_all_as_seen') }}
          </span>
        </v-tooltip>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-list v-if="decoratedNotifications.length" class="overflow-y-auto" max-height="400px">
          <v-list-item
            v-for="n in decoratedNotifications"
            :key="n.id"
            :inactive="!n.hasOnClickAction"
            :ripple="n.hasOnClickAction"
            @click="n.onNotificationClick"
          >
            <v-list-item-content>
              <v-list-item-title
                v-html="n.title"
                :class="{ link: n.hasOnClickAction }"
                class="text-preserve-whitespace"
              />
              <v-list-item-subtitle v-html="n.body" class="text-preserve-whitespace" />
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click.stop.prevent="toggleSeen(n)">
                    <v-icon :color="n.is_seen ? 'grey' : 'error'" small>
                      {{ n.is_seen ? 'mdi-circle-outline' : 'mdi-circle' }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t(n.is_seen ? 'mark_as_unseen' : 'mark_as_seen') }}
                </span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <div v-else-if="!hasMore" class="subtitle-1 px-4 py-2">
          {{ $t('no_reminders_present') }}
        </div>
      </v-card-text>
      <v-card-actions v-if="hasMore">
        <v-btn
          :disabled="isDataLoading"
          :loading="isDataLoading"
          color="primary"
          block
          text
          @click="getNotifications"
        >
          {{ $t('show_more') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import crudMixin from '../mixins/crud-mixin';
import notificationService from '../api/notification-service';
import { updateItemById } from '../util/array';
import orderPartService from '@/api/order-part-service';
import inventoryItemService from '@/api/inventory-item-service';

export default {
  name: 'Notifications',

  mixins: [crudMixin],

  data() {
    return {
      intervalID: null,
      isDataLoading: true,
      isMenuOpen: false,
      notificationArray: [],
      notificationPagination: {},
      unreadNotificationCount: 0,
    };
  },

  computed: {
    hasMore() {
      const { page, rowsPerPage, totalItems } = this.notificationPagination;
      if (!page) {
        return true;
      }
      return rowsPerPage * page <= totalItems;
    },

    decoratedNotifications() {
      return this.notificationArray.map(n => {
        let hasOnClickAction = false;
        let onClick;

        const { model_class_name, model_id } = n;
        if (model_class_name.includes('OrderPart')) {
          onClick = () => this.viewOrderPart(model_id);
        } else if (model_class_name.includes('InventoryItem')) {
          onClick = () => this.viewInventoryItem(model_id);
        }

        if (onClick) {
          hasOnClickAction = true;
        } else {
          // Vue complains if null is passed into @click event, don't want to repeat template
          onClick = () => {};
        }

        return {
          ...n,
          hasOnClickAction,
          onNotificationClick: onClick,
        };
      });
    },
  },

  created() {
    this.getUnreadNotificationCount();
    this.intervalID = setInterval(() => {
      this.getUnreadNotificationCount();
    }, 30000);
  },

  beforeDestroy() {
    clearInterval(this.intervalID);
  },

  methods: {
    getUnreadNotificationCount() {
      notificationService.getUnreadCount().then(res => {
        if (res?.data?.count) {
          this.unreadNotificationCount = res.data.count;
        }
      });
    },

    getNotifications() {
      const page = this.notificationPagination.page ? this.notificationPagination.page + 1 : 1;
      this.isDataLoading = true;
      this.crudMixin_getPage(
        notificationService.getLatest,
        notificationService.model,
        page,
        null,
        true,
      );
    },

    markAllAsSeen() {
      notificationService.markAllAsSeen().then(() => {
        this.notificationArray = this.notificationArray.map(n => ({ ...n, is_seen: true }));
        this.unreadNotificationCount = 0;
      });
    },

    toggleSeen(notification) {
      notificationService.toggleSeen(notification).then(res => {
        this.notificationArray = updateItemById(this.notificationArray, res.data);
        this.unreadNotificationCount = res.data.is_seen
          ? --this.unreadNotificationCount
          : ++this.unreadNotificationCount;
      });
    },

    onMenuToggle(val) {
      if (val) {
        this.notificationArray = [];
        this.notificationPagination = [];
        this.isDataLoading = true;
        this.getNotifications();
      }
    },

    async viewOrderPart(id) {
      const { data } = await orderPartService.getById(id, { all_relations: 1 });
      await this.$router.push({
        name: 'orders',
        query: { search: data.order.order_no, orderPartId: data.id },
      });
    },

    async viewInventoryItem(id) {
      const { data } = await inventoryItemService.getById(id);
      await this.$router.push({
        name: 'inventory-items',
        query: { title: data.title },
      });
    },
  },
};
</script>

<style scoped>
.notifications-card {
  width: 414px;
}
</style>

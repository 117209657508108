import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import lt from 'vuetify/es5/locale/lt';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { lt },
    current: 'lt',
  },
  theme: {
    options: {
      customProperties: true,
    },
  },
});

<template>
  <v-app-bar
    height="56"
    class="border-b"
    style="background-color: white"
    app
    clipped-left
    clipped-right
    elevate-on-scroll
  >
    <v-app-bar-nav-icon @click.stop="toggleNavigationDrawer" />
    <template v-if="$vuetify.breakpoint.smAndUp">
      <img
        v-if="logoUrl"
        :src="logoUrl"
        alt="logo"
        class="clickable ml-5 mr-1"
        height="30"
        @click="$router.push({ path: '/' })"
      />
      <span class="title">
        {{ title }}
      </span>
    </template>

    <v-spacer />

    <!--    <LanguageSelector/>-->

    <Notifications v-if="currentUser.role !== 'worker'" />

    <WorkshopHelpMenu v-if="currentUser.role === 'worker'" />

    <v-btn v-if="$store.getters.impersonatedUserId" text @click="stopImpersonating">
      {{ $t('stop_impersonating') }}
    </v-btn>

    <v-btn v-else-if="currentUser.role === 'worker'" text @click="logout">
      {{ $t('logout') }}
    </v-btn>

    <v-menu v-else bottom left offset-y>
      <template v-slot:activator="{ on }">
        <v-btn large icon color="primary" v-on="on">
          <v-icon large>mdi-account-circle</v-icon>
        </v-btn>
      </template>

      <v-list subheader dense>
        <v-subheader class="subtitle-2">
          {{ currentUser.email }}
        </v-subheader>
        <v-divider />
        <v-list-item v-if="currentUser.role !== 'worker'" @click="viewProfile">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('my_profile') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$store.getters.impersonatedUserId" @click="stopImpersonating">
          <v-list-item-icon>
            <v-icon>mdi-stop</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('stop_impersonating') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { UPDATE_SETTING } from '../store/mutation-types';
import Notifications from './Notifications';
import WorkshopHelpMenu from './WorkshopHelpMenu';
import { getAppLogoUrl, getAppTitle } from '@/util/company-attributes';

export default {
  name: 'Toolbar',

  components: { WorkshopHelpMenu, Notifications },

  data() {
    return {
      logoUrl: getAppLogoUrl(),
      title: getAppTitle(),
    };
  },

  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },

  methods: {
    viewProfile() {
      this.$router.push({ name: 'profile' });
    },

    toggleNavigationDrawer() {
      this.$store.commit(UPDATE_SETTING, {
        key: 'sidenavOpen',
        value: !this.$store.state.settings.sidenavOpen,
      });
    },

    stopImpersonating() {
      this.$store.dispatch('stopImpersonating');
    },

    logout() {
      this.$store.dispatch('logout');
    },
  },
};
</script>

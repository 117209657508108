// used only for worker view for now
import orderPartService from '../../api/order-part-service';

const state = {
  orderParts: null,
  orderPartUpdateCounter: 0,
};

const getters = {};

const mutations = {
  SET_ORDER_PARTS(state, parts) {
    state.orderParts = parts;
  },

  UPDATE_ORDER_PART(state, part) {
    for (let i = 0; i < state.orderParts.length; i++) {
      if (state.orderParts[i].id === part.id) {
        state.orderParts.splice(i, 1, {
          ...state.orderParts[i],
          ...part,
        });
        break;
      }
    }
    state.orderPartUpdateCounter += 1;
  },
};

const actions = {
  async fetchOrderParts({ commit }) {
    const { data } = await orderPartService.getAll();
    commit('SET_ORDER_PARTS', data);
  },

  async fetchOrderPart({ commit }, id) {
    const { data } = await orderPartService.getById(id);
    commit('UPDATE_ORDER_PART', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

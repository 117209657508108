import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import registerHelpers from './util/helpers';
import registerFilters from './util/filters';
import i18n from './i18n/i18n-config';
import App from './App.vue';

Vue.config.productionTip = false;
registerFilters(Vue);
registerHelpers(Vue);

if (
  process.env.NODE_ENV === 'production' &&
  process.env.VUE_APP_SENTRY_KEY &&
  process.env.VUE_APP_SENTRY_KEY !== 'VUE_APP_SENTRY_KEY_PLACEHOLDER' // default value in env template
) {
  Sentry.init({
    environment: 'staging',
    dsn: process.env.VUE_APP_SENTRY_KEY,
    integrations: [new Integrations.Vue({ Vue, attachProps: false })],
    ignoreErrors: [
      'Non-Error promise rejection',
      'NavigationDuplicated',
      'Request failed with status code',
      'Redirected when going from',
      'Request aborted',
      'Network error',
    ],
  });
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');

import http from './http';

const endpoint = 'api/notifications';

const notificationService = {
  model: 'notification',

  getUnreadCount: () => http.get(`${endpoint}/unread-count`),

  getLatest: page => http.get(`${endpoint}/latest?page=${page}`),

  markAllAsSeen: () => http.post(`${endpoint}/mark-all-as-seen`),

  toggleSeen: notification => http.patch(`${endpoint}/${notification.id}/toggle-is-seen-status`),
};

export default notificationService;

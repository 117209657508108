import { SET_WAREHOUSE_LOCATIONS } from '../mutation-types';
import { FETCH_WAREHOUSE_LOCATIONS } from '../action-types';
import warehouseLocationService from '../../api/warehouse-location-service';

const state = {
  locations: [],
};

const getters = {
  warehouseLocations(state) {
    return state.locations;
  },
};

const mutations = {
  [SET_WAREHOUSE_LOCATIONS](state, locations) {
    state.locations = locations;
  },
};

const actions = {
  async [FETCH_WAREHOUSE_LOCATIONS]({ commit }) {
    try {
      const res = await warehouseLocationService.getAll();
      commit(SET_WAREHOUSE_LOCATIONS, res.data);
    } catch (e) {
      throw new Error(e);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

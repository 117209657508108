import userService from '@/api/user-service';
import { arrayToMap } from '@/util/array';

const state = {
  currentUserUnfinishedProcesses: [], // for currently signed in employee
};

const getters = {
  currentUserUnfinishedProcessesMap(state) {
    return arrayToMap(state.currentUserUnfinishedProcesses, 'order_part_process_id');
  },
};

const mutations = {
  SET_CURRENT_USER_UNFINISHED_PROCESSES(state, { data }) {
    state.currentUserUnfinishedProcesses = data;
  },
};

const actions = {
  async fetchCurrentUnfinishedProcesses({ commit, rootState }) {
    const { currentUser } = rootState.auth;
    if (!currentUser) {
      return;
    }
    const { data } = await userService.getUserUnfinishedProcesses(1, currentUser.id);
    commit('SET_CURRENT_USER_UNFINISHED_PROCESSES', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

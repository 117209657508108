import Vue from 'vue';
import taskService from '@/api/task-service';
import { removeItemById, updateItemById } from '@/util/array';
import i18n from '@/i18n/i18n-config';
import eventBus, { OPEN_SNACKBAR } from '@/util/event-bus';
import { collectMultiSelectErrors, mapErrorsToInputs } from '@/util/forms';
import clone from 'just-clone';

export const getDefaultTaskFormItem = () => ({
  swim_lane_id: 1,
  users: [],
});

export const getDefaultTaskFilterParams = () => ({});

const state = {
  swimLaneTasksMap: {},
  archivedTasksPagination: {},
  loadingArchive: false,
  loadingTasks: false,
  syncingTasks: false,
  editedTask: {},
  newTask: getDefaultTaskFormItem(),
  taskValidationErrors: {},
  taskFilterParams: getDefaultTaskFilterParams(),
};

const getters = {
  swimLanes() {
    return [
      { id: 1, name: i18n.t('task_swim_lanes.ready'), color: 'grey lighten-4' },
      { id: 2, name: i18n.t('task_swim_lanes.postponed'), color: 'red lighten-5' },
      { id: 3, name: i18n.t('task_swim_lanes.in_progress'), color: 'blue lighten-5' },
      { id: 4, name: i18n.t('task_swim_lanes.done'), color: 'green lighten-5' },
      { id: 5, name: i18n.t('task_swim_lanes.archived'), color: 'grey lighten-2' },
    ];
  },
};

const mutations = {
  SET_SWIM_LANE_TASKS_MAP(state, map) {
    state.swimLaneTasksMap = map;
  },

  SET_TASKS(state, { tasks, swimLanes }) {
    const map = {};

    for (let i = 0; i < swimLanes.length; i++) {
      if (swimLanes[i].id === 5) {
        // don't touch the archive if it was already loaded
        map[swimLanes[i].id] = state.swimLaneTasksMap['5'] || [];
      } else {
        map[swimLanes[i].id] = [];
      }
    }

    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];
      if (map[task.swim_lane_id]) {
        map[task.swim_lane_id].push(task);
      }
    }

    for (let i = 0; i < swimLanes.length; i++) {
      map[swimLanes[i].id].sort((t1, t2) => (t1.sort?.sort > t2.sort?.sort ? 1 : -1));
    }

    state.swimLaneTasksMap = map;
    state.loadingTasks = false;
  },

  SET_ARCHIVED_TASKS_PAGE(state, { data, current_page, per_page, total }) {
    const alreadyLoadedTaskSet = new Set(state.swimLaneTasksMap['5'].map(t => t.id));

    data.sort((t1, t2) => (t1.sort?.sort > t2.sort?.sort ? 1 : -1));

    for (let i = 0; i < data.length; i++) {
      if (!alreadyLoadedTaskSet.has(data[i].id)) {
        state.swimLaneTasksMap['5'].push(data[i]);
      }
    }

    state.archivedTasksPagination = {
      current_page,
      per_page,
      total,
    };
    state.loadingArchive = false;
  },

  SET_SWIM_LANE_TASKS(state, { swimLaneId, tasks }) {
    state.swimLaneTasksMap[swimLaneId] = tasks;
  },

  SET_LOADING_ARCHIVE(state, value) {
    state.loadingArchive = value;
  },

  SET_LOADING_TASKS(state, value) {
    state.loadingTasks = value;
  },

  SET_NEW_TASK(state, task) {
    state.newTask = {
      ...getDefaultTaskFormItem(),
      ...task,
    };
  },

  SET_EDITED_TASK(state, task) {
    state.taskValidationErrors = {};
    state.editedTask = JSON.parse(JSON.stringify(task));
  },

  STORE_TASK(state, task) {
    state.swimLaneTasksMap[task.swim_lane_id].push(task);
    state.taskValidationErrors = {};
    state.newTask = getDefaultTaskFormItem();
  },

  UPDATE_TASK(state, task) {
    state.swimLaneTasksMap[task.swim_lane_id] = updateItemById(
      state.swimLaneTasksMap[task.swim_lane_id],
      task,
    );
  },

  DELETE_TASK(state, task) {
    state.swimLaneTasksMap[task.swim_lane_id] = removeItemById(
      state.swimLaneTasksMap[task.swim_lane_id],
      task.id,
    );
  },

  SET_TASK_VALIDATION_ERRORS(state, taskValidationErrors) {
    state.taskValidationErrors = taskValidationErrors;
  },

  CLEAR_TASK_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.taskValidationErrors, field);
  },
};

const actions = {
  async fetchTasks({ commit, getters }) {
    const { data } = await taskService.getAll({ non_paginated: true });
    commit('SET_TASKS', { tasks: data, swimLanes: getters.swimLanes });
  },

  async fetchArchive({ commit }, params = {}) {
    commit('SET_LOADING_ARCHIVE', true);
    const { data } = await taskService.getAll({ ...params, swim_lane_id: 5 });
    commit('SET_ARCHIVED_TASKS_PAGE', data);
  },

  async storeTask({ commit }, task) {
    try {
      const { data } = await taskService.create(task);
      commit('STORE_TASK', data);
      eventBus.$emit(OPEN_SNACKBAR, i18n.t('task_created'));
      return data;
    } catch (e) {
      const errors = mapErrorsToInputs(e);
      errors.users = collectMultiSelectErrors(errors, 'users');
      commit('SET_TASK_VALIDATION_ERRORS', errors);
      throw e;
    }
  },

  async editTask({ state, getters, commit }, taskId) {
    function findTaskInMap() {
      for (let i = 0; i < getters.swimLanes.length; i++) {
        const swimLaneId = getters.swimLanes[i].id;
        for (let j = 0; j < state.swimLaneTasksMap[swimLaneId]?.length; j++) {
          if (state.swimLaneTasksMap[swimLaneId][j].id === taskId) {
            return state.swimLaneTasksMap[swimLaneId][j];
          }
        }
      }
      return null;
    }

    const task = findTaskInMap();

    if (task) {
      commit('SET_EDITED_TASK', task);
      return Promise.resolve(task);
    }

    const { data } = await taskService.getById(taskId);
    commit('SET_EDITED_TASK', data);
    return data;
  },

  async updateTask({ state, commit }, { task, oldSwimLaneId }) {
    try {
      const { data } = await taskService.update(task);
      const newSwimLaneId = data.swim_lane_id;

      if (oldSwimLaneId !== newSwimLaneId) {
        commit('DELETE_TASK', {
          ...task,
          swim_lane_id: oldSwimLaneId,
        });
        commit('STORE_TASK', data);
        taskService.updateTasksOrder({
          sort: state.swimLaneTasksMap[newSwimLaneId].map(t => t.id),
        });
      } else {
        commit('UPDATE_TASK', data);
      }

      return data;
    } catch (e) {
      const errors = mapErrorsToInputs(e);
      errors.users = collectMultiSelectErrors(errors, 'users');
      commit('SET_TASK_VALIDATION_ERRORS', errors);
      throw e;
    }
  },

  async moveTask({ state, commit }, event) {
    const tasksMapBackup = clone(state.swimLaneTasksMap);

    try {
      const tasksMap = clone(state.swimLaneTasksMap);
      const movedTaskId = +event.item.attributes['data-task-id'].value;
      const oldSwimLaneId = +event.item.attributes['data-swim-lane-id'].value;
      const newSwimLaneId = +event.to.attributes['data-swim-lane-id'].value;
      const { oldIndex, newIndex } = event;

      if (oldSwimLaneId === newSwimLaneId && oldIndex === newIndex) {
        return;
      }

      const movedTask = state.swimLaneTasksMap[oldSwimLaneId].find(t => t.id === movedTaskId);
      if (!movedTask) {
        throw new Error('Task not found');
      }

      if (oldSwimLaneId !== newSwimLaneId) {
        movedTask.swim_lane_id = newSwimLaneId;
        taskService.update(movedTask).then(({ data }) => {
          commit('UPDATE_TASK', data);
        });
      }

      tasksMap[oldSwimLaneId].splice(oldIndex, 1);
      tasksMap[newSwimLaneId].splice(newIndex, 0, movedTask);
      commit('SET_SWIM_LANE_TASKS_MAP', tasksMap);

      await taskService.updateTasksOrder({ sort: tasksMap[newSwimLaneId].map(t => t.id) });
    } catch (e) {
      commit('SET_SWIM_LANE_TASKS_MAP', tasksMapBackup);
      eventBus.$emit(OPEN_SNACKBAR, i18n.t('task_move_failed'));
      throw e;
    }
  },

  async deleteTask({ commit }, task) {
    await taskService.delete(task);
    commit('DELETE_TASK', task);
    eventBus.$emit(OPEN_SNACKBAR, i18n.t('task_deleted'));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import { round } from './numbers';

const filters = {
  currency(val) {
    return !Number.isNaN(+val) ? `€${round(+val, 4).toFixed(4)}` : '';
  },

  percentage(val) {
    return !Number.isNaN(+val) ? `${round(+val, 4).toFixed(4)}%` : '-';
  },

  truncate(val, length) {
    return val && val.length > length ? `${val.slice(0, length)}...` : val;
  },
};

function registerFilters(Vue) {
  Object.keys(filters).forEach(filter => {
    Vue.filter(filter, filters[filter]);
  });
}

export { filters };

export default registerFilters;

/* eslint-disable no-param-reassign,no-restricted-globals */
import store from '../store';

const helpers = {
  $isAdmin() {
    if (store.getters.currentUser) {
      return store.getters.currentUser.role === 'admin';
    }
    return false;
  },

  $isCurrentUser(id) {
    if (store.getters.currentUser) {
      return store.getters.currentUser.id === id;
    }
    return false;
  },

  $capitalizeFirstLetter(s) {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  },

  $customAppTitle() {
    return process.env.VUE_APP_CUSTOM_APP_TITLE;
  },

  $getProperty(object, propertyName) {
    const parts = propertyName.split('.');
    let property = object || this;

    for (let i = 0; i < parts.length; i += 1) {
      if (property) {
        property = property[parts[i]];
      }
    }

    return property;
  },
};

function registerHelpers(Vue) {
  Object.keys(helpers).forEach(helper => {
    Vue.prototype[helper] = helpers[helper];
  });
}

export default registerHelpers;

import Vue from 'vue';
import i18n from '@/i18n/i18n-config';

const eventBus = new Vue();

// event types
export const OPEN_CONFIRM_DIALOG = 'open-confirm-dialog';
export const CONFIRM_DIALOG_CLOSED = 'confirm-dialog-closed';
export const OPEN_SNACKBAR = 'open-snackbar';
export const SHOW_LOADER = 'show-loader';
export const HIDE_LOADER = 'hide-loader';

export const UPDATE_INVENTORY_ITEM_QUANTITIES_IN_WAREHOUSE =
  'updateInventoryItemQuantitiesInWarehouse';

// helper to open confirm dialog with less code
export const openConfirmDialog = params => {
  eventBus.$emit(OPEN_CONFIRM_DIALOG, {
    title: params?.title || i18n.t('confirm_entry_delete'),
    body: params?.body,
    confirmText: params?.confirmText,
    cancelText: params?.cancelText,
    customActions: params?.customActions,
  });
  return new Promise(resolve => {
    eventBus.$on(CONFIRM_DIALOG_CLOSED, async confirmed => {
      resolve(confirmed);
      eventBus.$off(CONFIRM_DIALOG_CLOSED);
    });
  });
};

export function openSnackbar(params) {
  eventBus.$emit(OPEN_SNACKBAR, params);
}

export default eventBus;

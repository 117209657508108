import tagService from '../../api/tag-service';

const state = {
  tags: [],
};

const getters = {};

const mutations = {
  SET_TAGS(state, tags) {
    state.tags = tags;
  },
};

const actions = {
  async fetchTags({ commit }) {
    const res = await tagService.getAll();
    commit('SET_TAGS', res.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import http from './http';

const endpoint = 'oauth/token';

const AUTH_PARAMS = {
  scope: process.env.VUE_APP_SCOPE,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
};

const authService = {
  login: (username, password) => {
    const payload = {
      ...AUTH_PARAMS,
      grant_type: process.env.VUE_APP_GRANT_TYPE,
      username,
      password,
    };
    return http.post(`${endpoint}`, payload);
  },

  quickLogin: params => http.get('api/auth/token-by-user-hash', { params }),

  refreshToken: token => {
    const payload = {
      ...AUTH_PARAMS,
      grant_type: 'refresh_token',
      refresh_token: token,
    };
    return http.post(`${endpoint}`, payload);
  },
};

export default authService;

/* eslint-disable no-param-reassign */

function getKey(key, prefix) {
  return prefix.length ? `${prefix}[${key}]` : key;
}

function getFormData(item, fieldBlacklist = [], formData = new FormData(), prefix = '') {
  Object.keys(item).forEach(key => {
    if (item[key] === null || fieldBlacklist.indexOf(key) > -1) {
      return;
    }
    if (typeof item[key] === 'object' && item[key].length > 0) {
      // array
      formData = getFormData({ ...item[key] }, fieldBlacklist, formData, getKey(key, prefix));
    } else if (item[key] instanceof File) {
      formData.append(getKey(key, prefix), item[key]);
    } else if (typeof item[key] === 'object') {
      formData = getFormData(item[key], fieldBlacklist, formData, getKey(key, prefix));
    } else if (typeof item[key] === 'boolean') {
      formData.append(getKey(key, prefix), item[key] ? '1' : '0');
    } else {
      // string or number
      formData.append(getKey(key, prefix), item[key]);
    }
  });
  return formData;
}

export default getFormData;

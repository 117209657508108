<template>
  <v-app>
    <template v-if="!hideNavDrawerAndToolbar">
      <Toolbar />
      <WorkerNavigationDrawer v-if="currentUser.role === 'worker'" />
      <NavigationDrawer v-else />
    </template>

    <ConfirmDialog />
    <Snackbar />
    <v-main class="transition-none">
      <router-view :key="routeName" />
    </v-main>

    <UpdateNotifier v-if="currentUser" />
    <NoConnectionNotifier />
    <QrScannerListener :redirect-fragments="scannerRedirectFragments" />
  </v-app>
</template>

<script>
import Toolbar from './components/Toolbar';
import NavigationDrawer from './components/NavigationDrawer';
import Snackbar from './components/Snackbar';
import ConfirmDialog from './components/ConfirmDialog';
import { SET_TOUCH_DEVICE, UPDATE_SETTING } from './store/mutation-types';
import WorkerNavigationDrawer from './components/WorkerNavigationDrawer';
import UpdateNotifier from '@/components/UpdateNotifier';
import NoConnectionNotifier from '@/components/NoConnectionNotifier';
import QrScannerListener from '@/components/QrScannerListener';

export default {
  name: 'App',

  components: {
    QrScannerListener,
    NoConnectionNotifier,
    UpdateNotifier,
    WorkerNavigationDrawer,
    ConfirmDialog,
    Snackbar,
    Toolbar,
    NavigationDrawer,
  },

  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },

    scannerRedirectFragments() {
      const fragments = ['quick-auth'];
      if (this.currentUser) {
        fragments.push('order-part');
      }
      if (this.currentUser?.role === 'admin') {
        fragments.push('inventory-item-purchase-details');
      }
      return fragments;
    },

    hideNavDrawerAndToolbar() {
      const routes = ['machineries-print', 'quick-auth'];
      return !this.currentUser || routes.includes(this.$route.name);
    },

    routeName() {
      const { name } = this.$route;
      if (name && name.toLowerCase().includes('task')) {
        return 'tasks';
      }
      if (name && name.toLowerCase().includes('invoice')) {
        return 'invoice';
      }

      return name;
    },
  },

  created() {
    this.addTouchListener();
    if (this.$vuetify.breakpoint.smAndUp) {
      this.$store.commit(UPDATE_SETTING, {
        key: 'sidenavOpen',
        value: true,
      });
    }
    // Client requested auto logout for workers to be temporarily disabled
    // if (this.currentUser?.role === 'worker' && !this.$store.getters.impersonatedUserId) {
    //   window.addEventListener('touchstart', this.setupInactivityTracking);
    //   window.addEventListener('scroll', this.setupInactivityTracking);
    //   window.addEventListener('keypress', this.setupInactivityTracking);
    //   window.addEventListener('click', this.setupInactivityTracking);
    // }
  },

  methods: {
    setupInactivityTracking() {
      this.$store.dispatch('startAutoLogoutTimer');
    },

    addTouchListener() {
      window.addEventListener('touchstart', () => {
        if (!this.isTouchDevice) {
          this.$store.commit(SET_TOUCH_DEVICE);
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import './styles.scss';
@import './vuetify-customizations.scss';
</style>

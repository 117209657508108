<template>
  <v-navigation-drawer
    :value="$store.state.settings.sidenavOpen"
    height="100%"
    width="274"
    app
    clipped
    @input="toggleDrawer"
  >
    <v-list dense nav>
      <template v-for="(item, i) in displayedItems">
        <v-subheader v-if="item.heading" :key="i" class="subtitle-2">
          {{ item.heading }}
        </v-subheader>

        <v-divider v-else-if="item.divider" :key="i" class="my-2" />

        <v-list-item
          v-else
          :key="i"
          :disabled="!item.url"
          :to="{ path: item.url, query: item.query || {} }"
          active-class="primary--text"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t(item.text) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-list-item link @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('logout') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="d-flex align-center justify-center py-4 border-t">
        <span class="mr-2 subtitle-2"> {{ $t('developed_by') }} </span>
        <a :href="$t('evolco_url')" class="d-flex" target="_blank">
          <img src="../assets/evolco-logo.png" alt="" height="20" />
        </a>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';
import { UPDATE_SETTING } from '../store/mutation-types';
import { getDefaultOrderFilterParams } from '@/api/order-service';

export default {
  computed: {
    ...mapState('invoices', ['invoiceFilterParams']),

    displayedItems() {
      const items = [
        {
          icon: 'mdi-warehouse',
          text: 'finished_goods_warehouse',
          url: '/inventory-items',
          allowedRoles: ['admin'],
        },
        {
          icon: 'mdi-file-document-box-check',
          text: 'orders',
          url: '/orders',
          query: getDefaultOrderFilterParams(),
          allowedRoles: ['admin'],
        },
        // {
        //   icon: 'mdi-file-document-box',
        //   text: 'offers',
        //   url: '/offers',
        //   allowedRoles: ['admin'],
        // },
        // {
        //   icon: 'mdi-currency-eur',
        //   text: 'general.invoices',
        //   url: '/invoices',
        //   query: this.invoiceFilterParams,
        //   allowedRoles: ['admin'],
        // },
        {
          icon: 'mdi-layers-triple',
          text: 'materials_warehouse',
          url: '/order-part-materials',
          allowedRoles: ['admin'],
        },
        // {
        //   icon: 'mdi-tanker-truck',
        //   text: 'dispatch_history',
        //   url: '/inventory-item-dispatches',
        //   allowedRoles: ['admin'],
        // },
        {
          icon: 'mdi-package',
          text: 'supplying',
          url: '/inventory-item-purchases',
          allowedRoles: ['admin'],
        },
        {
          icon: 'mdi-folder-open',
          text: 'documents',
          url: '/document-library',
          allowedRoles: ['admin'],
        },
        {
          icon: 'mdi-select-off',
          text: 'order_defects',
          url: '/order-defects',
          allowedRoles: ['admin'],
        },
        {
          icon: 'mdi-wrench',
          text: 'processes',
          url: '/processes',
          allowedRoles: ['admin'],
        },
        {
          icon: 'mdi-cogs',
          text: 'machineries',
          url: '/machineries',
          allowedRoles: ['admin'],
        },
        {
          icon: 'mdi-map-marker',
          text: 'warehouse_locations',
          url: '/warehouse-locations',
          allowedRoles: ['admin'],
        },
        {
          icon: 'mdi-notebook',
          text: 'clients',
          url: '/clients',
          allowedRoles: ['admin'],
        },
        {
          icon: 'mdi-truck',
          text: 'suppliers',
          url: '/suppliers',
          allowedRoles: ['admin'],
        },
        {
          icon: 'mdi-account-multiple',
          text: 'users',
          url: '/users',
          allowedRoles: ['admin'],
        },
        {
          icon: 'mdi-timer',
          text: 'user_activity',
          url: '/user-activity',
          allowedRoles: ['admin'],
        },
        // {
        //   icon: 'mdi-currency-eur',
        //   text: 'sales',
        //   url: '/sales',
        //   allowedRoles: ['admin'],
        // },
        {
          icon: 'mdi-chart-timeline-variant',
          text: 'salary_report',
          url: '/salary-report',
          allowedRoles: ['admin'],
        },
        // {
        //   icon: 'mdi-format-list-checks',
        //   text: 'tasks',
        //   url: '/tasks',
        //   allowedRoles: ['admin'],
        // },
        { divider: true, allowedRoles: ['admin'] },
        { icon: 'mdi-account', text: 'my_profile', url: '/profile' },
      ];

      return items.filter(
        i => !i.allowedRoles || i.allowedRoles.indexOf(this.currentUser.role) > -1,
      ); // eslint-disable-line max-len
    },

    currentUser() {
      return this.$store.getters.currentUser;
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('logout');
    },

    toggleDrawer(val) {
      this.$store.commit(UPDATE_SETTING, {
        key: 'sidenavOpen',
        value: val,
      });
    },
  },
};
</script>

/* eslint-disable import/prefer-default-export */
import logo from '@/assets/kagneta-logo.png';
import loginBackground from '@/assets/login-background.jpg';

export function getAppLogoUrl() {
  const { customAppLogoUrl } = localStorage;
  const { VUE_APP_CUSTOM_APP_LOGO_URL } = process.env;
  if (customAppLogoUrl === '-' || VUE_APP_CUSTOM_APP_LOGO_URL === '-') {
    return '';
  }
  return customAppLogoUrl || VUE_APP_CUSTOM_APP_LOGO_URL || logo;
}

export function getAppLoginBackgroundUrl() {
  const { customAppLoginBackgroundUrl } = localStorage;
  const { VUE_APP_CUSTOM_APP_LOGIN_BACKGROUND_URL } = process.env;
  if (customAppLoginBackgroundUrl === '-' || VUE_APP_CUSTOM_APP_LOGIN_BACKGROUND_URL === '-') {
    return '';
  }
  return customAppLoginBackgroundUrl || VUE_APP_CUSTOM_APP_LOGIN_BACKGROUND_URL || loginBackground;
}

export function getAppTitle() {
  const { customAppTitle } = localStorage;
  const { VUE_APP_CUSTOM_APP_TITLE } = process.env;
  if (customAppTitle === '-' || VUE_APP_CUSTOM_APP_TITLE === '-') {
    return '';
  }
  return customAppTitle || VUE_APP_CUSTOM_APP_TITLE || 'kagneta';
}

<template>
  <v-dialog
    v-model="isOpen"
    max-width="400"
    transition="slide-y-reverse-transition"
    style="z-index: 9999"
    persistent
  >
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>

      <v-card-text>
        {{ body }}
      </v-card-text>

      <v-card-actions v-if="customActions.length">
        <div class="w-full">
          <v-btn
            v-for="(action, index) in customActions"
            :key="action.value"
            :color="action.color || 'primary'"
            :class="{ 'mb-2': index < customActions.length - 1 }"
            block
            outlined
            @click.native="onCustomActionClick(action.value)"
          >
            {{ action.text }}
          </v-btn>
        </div>
      </v-card-actions>

      <v-card-actions v-else-if="!workerView">
        <v-spacer />
        <v-btn color="primary" text @click.native="onCancel">
          {{ cancelText }}
        </v-btn>
        <v-btn color="primary" ref="confirmBtn" text @click.native="onConfirm">
          {{ confirmText }}
        </v-btn>
      </v-card-actions>

      <v-card-actions v-else>
        <v-row dense>
          <v-col cols="12" sm="6" class="px-4 pb-3 pb-sm-1">
            <v-btn color="primary" block x-large outlined @click.native="onCancel">
              {{ cancelText }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" class="px-4 pb-3 pb-sm-1">
            <v-btn color="primary" ref="confirmBtn" block x-large @click.native="onConfirm">
              {{ confirmText }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import eventBus, { CONFIRM_DIALOG_CLOSED, OPEN_CONFIRM_DIALOG } from '../util/event-bus';

export default {
  name: 'ConfirmDialog',

  data() {
    return {
      isOpen: false,
      title: '',
      body: '',
      cancelText: '',
      confirmText: '',
      customActions: [
        // { text: '', color: '', value: '' }
      ],
    };
  },

  computed: {
    workerView() {
      return this.$route.path.includes('workshop');
    },
  },

  created() {
    eventBus.$on(OPEN_CONFIRM_DIALOG, async options => {
      this.title = typeof options.title === 'string' ? options.title : this.$t('are_you_sure');
      this.body = typeof options.body === 'string' ? options.body : '';
      this.cancelText =
        typeof options.cancelText === 'string' ? options.cancelText : this.$t('cancel');
      this.confirmText =
        typeof options.confirmText === 'string' ? options.confirmText : this.$t('confirm');
      this.customActions = options.customActions || [];
      this.isOpen = true;
      await this.$nextTick();
      if (this.$refs.confirmBtn && this.$refs.confirmBtn.$el) {
        this.$refs.confirmBtn.$el.focus();
      }
    });
  },

  methods: {
    onConfirm() {
      eventBus.$emit(CONFIRM_DIALOG_CLOSED, true);
      this.isOpen = false;
    },

    onCancel() {
      eventBus.$emit(CONFIRM_DIALOG_CLOSED, false);
      this.isOpen = false;
    },

    onCustomActionClick(value) {
      eventBus.$emit(CONFIRM_DIALOG_CLOSED, value);
      this.isOpen = false;
    },
  },
};
</script>

<template>
  <v-snackbar v-model="isOpen" :timeout="-1" top>
    {{ $t('update_available') }}

    <template v-slot:action="{ attrs }">
      <v-btn color="accent" text v-bind="attrs" @click="reloadPage">
        {{ $t('reload_page') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UpdateNotifier',

  data() {
    return {
      isOpen: false,
      currentVersion:
        document.querySelector("meta[name='app-version']")?.getAttribute('content') || '',
      fetchIntervalId: null,
    };
  },

  computed: {
    metadataUrl() {
      return `${process.env.VUE_APP_PUBLIC_PATH}metadata.json`;
    },
  },

  async mounted() {
    console.log('Current version: ', this.currentVersion);
    await this.checkForUpdates();
    this.fetchIntervalId = setInterval(this.checkForUpdates, 60000);
  },

  beforeDestroy() {
    clearInterval(this.fetchIntervalId);
  },

  methods: {
    reloadPage() {
      const url = new URL(window.location.href);
      url.searchParams.delete('no-cache');
      url.searchParams.append('no-cache', new Date().getTime().toString());
      window.location.href = url.href;
    },

    async checkForUpdates() {
      const { data } = await axios.get(`${this.metadataUrl}?no-cache=${new Date().getTime()}`);
      if (data.version !== this.currentVersion) {
        console.log('New version available: ', data.version);
        this.isOpen = true;
      }
    },
  },
};
</script>

<style scoped></style>

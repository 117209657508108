<template>
  <v-navigation-drawer
    :value="$store.state.settings.sidenavOpen"
    mobile-breakpoint="500"
    width="200"
    app
    clipped
    @input="toggleDrawer"
  >
    <v-fade-transition hide-on-leave>
      <div v-if="!orderParts">
        <v-skeleton-loader class="mt-3" type="list-item" />
        <v-skeleton-loader v-for="num in [1, 2, 3, 4, 5, 6]" :key="num" type="list-item-two-line" />
        <v-divider class="my-2" />
        <v-skeleton-loader type="list-item" />
      </div>

      <v-list v-else nav dense>
        <v-text-field
          v-model="searchQuery"
          :label="$t('search')"
          class="mt-0 px-2"
          single-line
          clearable
          prepend-inner-icon="mdi-magnify"
        />

        <v-list-item
          v-for="part in filteredParts"
          :key="part.id"
          :to="{ name: 'worker-order-part', params: { id: part.id } }"
          :ripple="false"
          active-class="primary--text"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ part.inventory_item && part.inventory_item.title }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ part.order.order_no }}.{{ part.order_part_no }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="d-flex flex-wrap">
              <div
                v-for="p in partProcesses[part.id]"
                :key="p.id"
                :class="statusColors[p.status].color"
                class="status-bubble-small"
              >
                <v-icon v-if="canEditProcess(p)" :color="statusColors[p.status].iconColor" x-small
                  >mdi-account-check</v-icon
                >
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-2" />

        <v-list-item
          :to="{ name: 'salary-report', params: { tab: 'chart' } }"
          active-class="primary--text"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('salary_report') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="logout">
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('logout') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-fade-transition>

    <template v-slot:append>
      <div class="d-flex align-center justify-center py-4 border-t">
        <span class="mr-2 subtitle-2"> {{ $t('developed_by') }}: </span>
        <a :href="$t('evolco_url')" class="d-flex" target="_blank">
          <img src="../assets/evolco-logo.png" alt="" height="20" />
        </a>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';
import { UPDATE_SETTING } from '../store/mutation-types';
import { getOrderPartProcessStatusMap } from '../api/order-part-service';

export default {
  name: 'WorkerNavigationDrawer',

  data() {
    return {
      searchQuery: '',
      statusColors: getOrderPartProcessStatusMap(),
    };
  },

  computed: {
    ...mapState('orderParts', ['orderParts']),

    filteredParts() {
      if (!this.orderParts) {
        return [];
      }
      if (!this.searchQuery) {
        return this.orderParts;
      }
      const q = this.searchQuery.toLowerCase();
      return this.orderParts.filter(part => {
        if (part.order?.order_no?.toLowerCase().indexOf(q) > -1) {
          return true;
        }
        return part.inventory_item?.title?.toLowerCase().indexOf(q) > -1;
      });
    },

    partProcesses() {
      if (!this.orderParts) {
        return [];
      }
      const partProcesses = {};
      for (let i = 0; i < this.orderParts.length; i++) {
        const part = this.orderParts[i];
        const processes = [];
        for (let j = 0; j < part.processes.length; j++) {
          const p = part.processes[j];
          processes.push({
            id: p.id,
            process_id: p.process.id,
            title: p.process.title,
            status: p.status,
          });
        }
        partProcesses[part.id] = processes;
      }
      return partProcesses;
    },
  },

  created() {
    this.$store.dispatch('orderParts/fetchOrderParts');
  },

  methods: {
    canEditProcess(process) {
      return this.$store.getters.currentUserProcessIds.has(process.process_id);
    },

    logout() {
      this.$store.dispatch('logout');
    },

    toggleDrawer(val) {
      this.$store.commit(UPDATE_SETTING, {
        key: 'sidenavOpen',
        value: val,
      });
    },
  },
};
</script>

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#browser_name

export function isSafari() {
  const ua = navigator.userAgent;
  return ua.includes('Safari/') && !ua.includes('Chrome/') && !ua.includes('Chromium/');
}

export function isFirefox() {
  const ua = navigator.userAgent;
  return ua.includes('Firefox/') && !ua.includes('Seamonkey/');
}

import httpClient from '@/api/http';

const endpoint = 'api/invoices';

const invoiceService = {
  getPage: params => httpClient.get(endpoint, { params }),

  getTotalSales: params => httpClient.get(`${endpoint}/total-sales`, { params }),

  getById: id => httpClient.get(`${endpoint}/${id}`),

  download: (id, params) =>
    httpClient({
      url: `${endpoint}/download/${id}`,
      method: 'GET',
      responseType: 'blob',
      params,
    }),

  sendViaEmail: (invoiceId, data, lang) =>
    httpClient.post(`${endpoint}/${invoiceId}/send/via-email`, data, { params: { lang } }),

  search: query => httpClient.get(`${endpoint}/find/${query}`),

  create: invoice => httpClient.post(endpoint, invoice),

  update: invoice => httpClient.put(`${endpoint}/${invoice.id}`, invoice),

  updateNumberCounter: data => httpClient.put(`${endpoint}/settings/last-invoice-no`, data),

  markAsPaid: invoice => httpClient.put(`${endpoint}/${invoice.id}/payment-received`, invoice),

  invalidate: invoice => httpClient.put(`${endpoint}/${invoice.id}/invalidate`, invoice),

  delete: invoice => httpClient.delete(`${endpoint}/${invoice.id}`),
};

export default invoiceService;

/* eslint-disable import/prefer-default-export */

// http://www.jacklmoore.com/notes/rounding-in-javascript/
function round(value, decimals = 4) {
  if (!+value) {
    return value;
  }
  return Number(`${Math.round(`${+value}e${decimals}`)}e-${decimals}`);
}

function ceil(value, decimals = 2) {
  if (!+value) {
    return value;
  }
  return Number(`${Math.ceil(`${+value}e${decimals}`)}e-${decimals}`);
}

function floor(value, decimals = 2) {
  if (!+value) {
    return value;
  }
  return Number(`${Math.floor(`${+value}e${decimals}`)}e-${decimals}`);
}

export { ceil, floor, round };

/* eslint-disable no-param-reassign,import/no-cycle */
import axios from 'axios';
import store from '../store';
import i18n from '../i18n/i18n-config';
import eventBus, { OPEN_SNACKBAR, openSnackbar } from '../util/event-bus';
import { getLocalStorageItem } from '@/util/local-storage';
import authService from '@/api/auth-service';

function getRequestKey(config) {
  return `${config.method}:${config.url}`;
}

function removeEmptyFilters(params) {
  const updatedParams = {};
  Object.keys(params).forEach(key => {
    if (typeof params[key] === 'boolean' || params[key]) {
      updatedParams[key] = params[key];
    }
  });
  return updatedParams;
}

function refreshToken() {
  if (store.state.auth.refreshTokenPromise) {
    return store.state.auth.refreshTokenPromise;
  }

  const token = getLocalStorageItem('refreshToken');
  if (!token) {
    return Promise.reject(new Error('Refresh token not found'));
  }

  const tokenRefreshPromise = authService
    .refreshToken(token)
    .then(res => {
      store.dispatch('handleAuthData', res.data);
      return Promise.resolve(true);
    })
    .finally(() => {
      store.commit('SET_REFRESH_TOKEN_PROMISE', null);
    });

  store.commit('SET_REFRESH_TOKEN_PROMISE', tokenRefreshPromise);
  return tokenRefreshPromise;
}

const http = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
});

http.interceptors.request.use(config => {
  if (store.getters.accessToken) {
    config.headers.common.Authorization = `Bearer ${store.getters.accessToken}`;
  }
  if (store.getters.impersonatedUserId) {
    config.headers.common['Impersonating-As'] = `${store.getters.impersonatedUserId}`;
  }
  config.headers.common.Locale = store.state.settings.locale;

  if (config.params) {
    config.params = removeEmptyFilters(config.params);
  }

  // console.log(JSON.stringify(config.data, null, 2));
  store.dispatch('setPendingRequest', getRequestKey(config));
  return config;
});

http.interceptors.response.use(
  res => {
    store.dispatch('removePendingRequest', getRequestKey(res.config));
    return res;
  },
  error => {
    if (!error?.response?.config) {
      openSnackbar({
        text: i18n.t('network_error'),
        timeout: 10000,
      });
      return Promise.reject(error);
    }
    store.dispatch('removePendingRequest', getRequestKey(error.response.config));
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          if (getRequestKey(error.response.config) === 'post:oauth/token') {
            // failed to refresh token
            return Promise.reject(error);
          }
          return refreshToken()
            .then(() => {
              error.config.headers.Authorization = `Bearer ${store.getters.accessToken}`;
              return axios.request(error.config);
            })
            .catch(() => {
              store.dispatch('logout');
              openSnackbar({
                text: i18n.t('session_expired'),
                timeout: 10000,
              });
              return Promise.reject(error);
            });
        case 409: {
          if (typeof error.response.data === 'string') {
            eventBus.$emit(OPEN_SNACKBAR, error.response.data);
          } else if (typeof error.response.data?.message === 'string') {
            eventBus.$emit(OPEN_SNACKBAR, error.response.data.message);
          }
          return Promise.reject(error);
        }
        case 500: {
          if (error.response.data.message && error.response.data.message.indexOf('login') > -1) {
            store.dispatch('logout').catch(() => {});
          } else {
            eventBus.$emit(OPEN_SNACKBAR, i18n.t('http_errors.500'));
          }
          return Promise.reject(error);
        }
        default: {
          /**
           *  List of error messages that have error messages written for them.
           *  To add another response code:
           *    1) add translations messages to src/i18n/{locale}/error-messages
           *    2) include the error code in showErrorMessagesFor array
           *  To add custom handling for a given error, see the above examples for 401 and 500
           */
          const showErrorMessagesFor = [401, 403, 404, 422, 429];
          if (showErrorMessagesFor.indexOf(error.response.status) > -1) {
            eventBus.$emit(OPEN_SNACKBAR, i18n.t(`http_errors.${error.response.status}`));
          }
          return Promise.reject(error);
        }
      }
    } else if (typeof error === 'string') {
      eventBus.$emit(OPEN_SNACKBAR, error);
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);

export default http;

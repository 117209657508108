<template>
  <v-img
    v-if="!$store.getters.currentUser"
    :src="backgroundUrl"
    height="100%"
    width="100%"
    style="position: fixed; text-align: center"
  >
    <v-container fluid class="fill-height">
      <v-row align="center" justify="center" style="max-width: 100%;" no-gutters>
        <v-col cols="12" sm="8" md="6" lg="4">
          <v-card class="elevation-12 pt-3">
            <!--            <div class="text-right pr-2 pt-2">-->
            <!--              <LanguageSelector/>-->
            <!--            </div>-->

            <v-img v-if="logoUrl" :src="logoUrl" height="50" contain />

            <span class="title">
              {{ title }}
            </span>

            <v-form ref="form" @submit.prevent="login">
              <v-card-text>
                <v-text-field
                  v-model="username"
                  :label="$t('username')"
                  prepend-icon="mdi-account"
                  name="username"
                  type="email"
                  autofocus
                />

                <v-text-field
                  v-model="password"
                  :append-icon="isPasswordHidden ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="isPasswordHidden ? 'password' : 'text'"
                  :label="$t('password')"
                  prepend-icon="mdi-lock"
                  name="password"
                  @click:append="isPasswordHidden = !isPasswordHidden"
                />
              </v-card-text>

              <v-card-actions>
                <v-btn
                  :loading="isRequestPending"
                  :disabled="isRequestPending"
                  color="primary"
                  type="submit"
                  block
                >
                  {{ $t('login') }}
                </v-btn>
              </v-card-actions>

              <v-card-actions v-if="showWorkerLoginLink">
                <v-btn
                  :disabled="isRequestPending"
                  color="primary"
                  type="button"
                  text
                  block
                  @click="$router.push('/workshop-login')"
                >
                  {{ $t('worker_login') }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>

      <SentryTester />
    </v-container>
  </v-img>
</template>

<script>
import authService from '../api/auth-service';
import eventBus, { HIDE_LOADER, OPEN_SNACKBAR } from '../util/event-bus';
import { getAppLoginBackgroundUrl, getAppLogoUrl, getAppTitle } from '@/util/company-attributes';
import SentryTester from '@/components/SentryTester.vue';

export default {
  name: 'Login',

  components: { SentryTester },

  data() {
    return {
      username: '',
      password: '',
      isPasswordHidden: true,
      isRequestPending: false,
      logoUrl: getAppLogoUrl(),
      backgroundUrl: getAppLoginBackgroundUrl(),
      title: getAppTitle(),
    };
  },

  computed: {
    showWorkerLoginLink() {
      return process.env.VUE_APP_ENVIRONMENT !== 'production';
    },
  },

  beforeRouteEnter(to, from, next) {
    if (window.location.hostname.indexOf('cechas') > -1) {
      next('/workshop-login');
    } else if (window.location.hostname.indexOf('tiekejams') > -1) {
      next('/supplier-portal');
    } else {
      next();
    }
  },

  created() {
    eventBus.$emit(HIDE_LOADER);
  },

  methods: {
    async login() {
      this.isRequestPending = true;
      try {
        const loginRes = await authService.login(this.username, this.password);
        await this.$store.dispatch('handleAuthData', loginRes.data);
        await this.$store.dispatch('getCurrentUser');
        await this.$router.replace('/');
      } catch (e) {
        if (e.response?.status === 401 || e.response?.status === 400) {
          eventBus.$emit(OPEN_SNACKBAR, this.$t('incorrect_username_or_password'));
        }
      }
      this.isRequestPending = false;
    },
  },
};
</script>

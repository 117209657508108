/* eslint-disable no-shadow,no-param-reassign,no-mixed-operators,import/no-cycle */
import router from '../../router/index';
import { FETCH_WAREHOUSE_LOCATIONS } from '../action-types';
import userService from '../../api/user-service';
import eventBus, { OPEN_SNACKBAR } from '../../util/event-bus';
import i18n from '../../i18n/i18n-config';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '@/util/local-storage';

const state = {
  accessToken: null,
  impersonatedUserId: null,
  currentUser: null,
  autoLogoutTimerId: null,
  refreshTokenPromise: null,
};

const getters = {
  currentUser(state) {
    return state.currentUser;
  },

  currentUserProcessIds(state) {
    if (!state.currentUser) {
      return new Set();
    }
    const processIds = state.currentUser.processes.map(p => p.id);
    return new Set(processIds);
  },

  accessToken(state) {
    return state.accessToken;
  },

  impersonatedUserId(state) {
    return state.impersonatedUserId;
  },
};

const mutations = {
  SET_ACCESS_TOKEN(state, accessToken) {
    state.accessToken = accessToken;
  },

  SET_CURRENT_USER(state, currentUser) {
    state.currentUser = currentUser.user || currentUser;
  },

  SET_AUTO_LOGOUT_TIMER(state, timerId) {
    state.autoLogoutTimerId = timerId;
  },

  CLEAR_AUTH_DATA(state) {
    state.accessToken = null;
    state.currentUser = null;
    state.impersonatedUserId = null;
  },

  SET_IMPERSONATED_USER_ID(state, id) {
    state.impersonatedUserId = id;
  },

  SET_REFRESH_TOKEN_PROMISE(state, promise) {
    state.refreshTokenPromise = promise;
  },
};

const actions = {
  handleAuthData({ commit }, payload) {
    commit('SET_ACCESS_TOKEN', payload.access_token);
    setLocalStorageItem('accessToken', payload.access_token);
    setLocalStorageItem('refreshToken', payload.refresh_token);
  },

  async tryAutoLogin({ state, commit, dispatch }) {
    if (state.accessToken && state.currentUser) {
      return;
    }

    const accessToken = getLocalStorageItem('accessToken');
    if (accessToken) {
      commit('SET_ACCESS_TOKEN', accessToken);
    } else {
      return;
    }

    const impersonatedUserId = getLocalStorageItem('impersonatedUserId');
    if (impersonatedUserId) {
      commit('SET_IMPERSONATED_USER_ID', +impersonatedUserId);
    }

    await dispatch('getCurrentUser');
  },

  logout({ commit }) {
    commit('CLEAR_AUTH_DATA');
    removeLocalStorageItem('accessToken');
    removeLocalStorageItem('refreshToken');
    removeLocalStorageItem('impersonatedUserId');
    clearTimeout(state.autoLogoutTimerId);
    router.replace('/login').catch(() => {});
  },

  async getCurrentUser({ commit, dispatch }) {
    let response;
    try {
      response = await userService.getCurrent();
      commit('SET_CURRENT_USER', response.data);
      // TODO move activity listeners here from app.vue
      // if (response.data.role === 'worker' && !state.impersonatedUserId) {
      //   dispatch('startAutoLogoutTimer);
      // }
      dispatch(FETCH_WAREHOUSE_LOCATIONS);
      if (response.data.role === 'worker') {
        dispatch('orderPartProcesses/fetchCurrentUnfinishedProcesses', null, { root: true });
      }
    } catch (e) {
      response = e;
      dispatch('logout');
    }
    return response;
  },

  async impersonateUser(context, id) {
    setLocalStorageItem('impersonatedUserId', id);
    const homePage = router.resolve('/').href;
    window.location.replace(homePage);
  },

  stopImpersonating() {
    removeLocalStorageItem('impersonatedUserId');
    const usersPage = router.resolve('/users').href;
    window.location.replace(usersPage);
  },

  // for factory workers
  startAutoLogoutTimer({ state, commit, dispatch }) {
    clearTimeout(state.autoLogoutTimerId);
    const timerId = setTimeout(() => {
      eventBus.$emit(OPEN_SNACKBAR, i18n.t('logged_out_due_to_inactivity'));
      dispatch('logout');
    }, 120000);
    commit('SET_AUTO_LOGOUT_TIMER', timerId);
  },
};

const auth = {
  state,
  getters,
  mutations,
  actions,
};

export default auth;

import http from './http';

const endpoint = 'api/warehouse-locations';

const warehouseLocationService = {
  model: 'warehouseLocation',

  getAll: params => http.get(endpoint, { params }),

  create: warehouseLocation => http.post(`${endpoint}`, warehouseLocation),

  update: warehouseLocation => http.put(`${endpoint}/${warehouseLocation.id}`, warehouseLocation),

  delete: warehouseLocation => http.delete(`${endpoint}/${warehouseLocation.id}`),
};

export default warehouseLocationService;

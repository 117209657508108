import http from './http';

const endpoint = 'api/users';

const userService = {
  model: 'user',

  getPage: (page, params) => http.get(endpoint, { params: { ...params, page } }),

  getById: async (id, simplified) => {
    const res = await http.get(`${endpoint}/${id}`);
    if (simplified) {
      res.data = {
        id: res.data.id,
        full_name: res.data.person.full_name,
      };
    }
    return res;
  },

  getSimpleList: () => http.get(`${endpoint}/simple-list`),

  getQuickAuthTokenRoute: user => http.get(`${endpoint}/${user.id}/quick-auth-token-route`),

  getUserActivityReport: params => http.get('api/user-activity-report', { params }),

  getUserUnfinishedProcesses: (page, id) =>
    http.get(`${endpoint}/${id}/unfinished-order-part-processes`, { params: page }),

  getWorkers: () => http.get(`${endpoint}/workers`),

  getCurrent: () => http.get(`${endpoint}/current`),

  search: query => http.get(`${endpoint}/find/${query}`),

  create: user => http.post(`${endpoint}`, user),

  update: user => http.put(`${endpoint}/${user.id}`, user),

  toggleStatus: user => http.patch(`${endpoint}/${user.id}/toggle-suspend`),

  resetPassword: user => http.post(`${endpoint}/${user.id}/reset-password`, user),

  changeCurrentUserPassword: payload => http.put(`${endpoint}/current/password`, payload),
};

export default userService;

<template>
  <v-menu :close-on-content-click="false" max-width="38em" offset-y>
    <template #activator="{ on }">
      <v-btn v-on="on" icon>
        <v-icon>mdi-help-circle</v-icon>
      </v-btn>
    </template>
    <v-sheet class="pa-3">
      <div class="mb-1">{{ $t('workshop_help.process_bubbles') }}:</div>
      <div class="mb-5">
        <div v-for="status in statuses" :key="status.value" class="d-flex mb-2">
          <div :class="status.color" class="status-bubble mr-3" />
          <span>{{ $t('process').toLowerCase() }} {{ status.text.toLowerCase() }}</span>
        </div>
      </div>
      <div>
        {{ $t('workshop_help.icon_inside_bubble') }} <v-icon small>mdi-account-check</v-icon>
        {{ $t('workshop_help.indicates_user_assignment') }}.
      </div>
    </v-sheet>
  </v-menu>
</template>

<script>
import { ORDER_PART_PROCESS_STATUSES } from '../api/order-part-service';

export default {
  name: 'WorkshopHelpMenu',

  data() {
    return {
      statuses: ORDER_PART_PROCESS_STATUSES,
    };
  },
};
</script>

<style scoped></style>

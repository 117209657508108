import {
  OPEN_MEASUREMENT_FORM, CLOSE_MEASUREMENT_FORM, SET_MEASUREMENTS,
  STORE_MEASUREMENT, UPDATE_MEASUREMENT, REMOVE_MEASUREMENT,
} from '../mutation-types';
import { removeItemById, updateItemById } from '../../util/array';
import { FETCH_MEASUREMENTS } from '../action-types';
import measurementService from '../../api/measurement.service';

const state = {
  measurements: [], // order part process measurements
  formItem: { values: [] },
  isFormOpen: false,
};

const getters = {};

const mutations = {
  [SET_MEASUREMENTS](state, measurements) {
    state.measurements = measurements;
  },
  [OPEN_MEASUREMENT_FORM](state, measurement) {
    state.formItem = { ...measurement };
    state.isFormOpen = true;
  },
  [CLOSE_MEASUREMENT_FORM](state) {
    state.formItem = {};
    state.isFormOpen = false;
  },
  [STORE_MEASUREMENT](state, measurement) {
    state.measurements.push(measurement);
  },
  [UPDATE_MEASUREMENT](state, measurement) {
    state.measurements = updateItemById(state.measurements, measurement);
  },
  [REMOVE_MEASUREMENT](state, measurement) {
    state.measurements = removeItemById(state.measurements, measurement.id);
  },
};

const actions = {
  async [FETCH_MEASUREMENTS]({ commit }, orderPartProcessId) {
    commit(SET_MEASUREMENTS, []);
    const params = { order_part_process_id: orderPartProcessId };
    const { data } = await measurementService.getPage(1, params);
    // TODO change to data when pagination is removed
    commit(SET_MEASUREMENTS, data.data ? data.data : data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import dialogs from './modules/dialogs';
import invoices from './modules/invoices';
import measurements from './modules/measurements';
import orderParts from './modules/order-parts';
import orderPartProcesses from './modules/order-part-processes';
import settings from './modules/settings';
import warehouseLocations from './modules/warehouse-locations';
import progress from './modules/progress';
import tags from './modules/tags';
import tasks from './modules/tasks';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},

  mutations: {},

  actions: {},

  modules: {
    auth,
    dialogs,
    invoices,
    measurements,
    orderParts,
    orderPartProcesses,
    settings,
    warehouseLocations,
    progress,
    tags,
    tasks,
  },
});

/* eslint-disable import/no-cycle */
import Vue from 'vue';
import VueRouter from 'vue-router';
import { authGuard, loginGuard, roleGuard } from './guards';
import Login from '../views/Login';
import store from '../store/index';
import { getDefaultOrderFilterParams } from '@/api/order-service';
import { invoiceRoutes } from '@/router/routes/invoice-routes';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: () =>
      store.getters.currentUser?.role === 'worker'
        ? { name: 'salary-report', params: { tab: 'chart' } }
        : { name: 'orders', query: getDefaultOrderFilterParams() },
  },
  // {
  //   path: '/',
  //   component: Home, // currently empty
  //   beforeEnter: authGuard,
  // },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: loginGuard,
  },
  {
    path: '/profile',
    name: 'profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // webpackChunkName says what will the import look like in the network tab.
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile'),
    beforeEnter: authGuard,
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users'),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
  },
  {
    path: '/user-activity',
    name: 'user-activity',
    component: () => import(/* webpackChunkName: "user-activity" */ '../views/UserActivityReport'),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import(/* webpackChunkName: "contacts" */ '../views/Contacts'),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
  },
  {
    path: '/suppliers',
    name: 'suppliers',
    component: () => import(/* webpackChunkName: "contacts" */ '../views/Contacts'),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
  },
  {
    path: '/processes/:tab',
    name: 'processes',
    component: () => import(/* webpackChunkName: "processes" */ '../views/Processes'),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
  },
  {
    path: '/processes',
    redirect: '/processes/list',
  },
  {
    path: '/machineries',
    name: 'machineries',
    component: () => import(/* webpackChunkName: "machineries" */ '../views/Machineries'),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
  },
  {
    path: '/warehouse-locations',
    name: 'warehouse-locations',
    component: () =>
      import(/* webpackChunkName: "warehouse-locations" */ '../views/WarehouseLocations'),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import(/* webpackChunkName: "orders" */ '../views/Orders'),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
  },
  {
    path: '/salary-report',
    redirect: '/salary-report/chart',
  },
  {
    path: '/salary-report/:tab',
    name: 'salary-report',
    component: () => import(/* webpackChunkName: "salary-report" */ '../views/SalaryReport'),
    beforeEnter: authGuard,
  },
  {
    path: '/inventory-item-purchases',
    name: 'inventory-item-purchases',
    component: () =>
      import(/* webpackChunkName: "inventory-item-purchases" */ '../views/InventoryItemPurchases'),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
  },
  {
    path: '/inventory-item-purchase-details/:id',
    name: 'inventory-item-purchase-details',
    component: () =>
      import(
        /* webpackChunkName: "inventory-item-purchase-details" */ '../views/InventoryItemPurchaseDetails'
      ),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
  },
  {
    path: '/order-defects',
    name: 'order-defects',
    component: () => import(/* webpackChunkName: "order-defects" */ '../views/OrderDefects'),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
  },
  // {
  //   path: '/offers-old',
  //   name: 'offers-old',
  //   component: () => import(/* webpackChunkName: "orders" */ '../views/Orders'),
  //   beforeEnter: roleGuard,
  //   meta: { allowedRoles: ['admin'] },
  // },
  // {
  //   path: '/offers',
  //   name: 'offers',
  //   component: () => import(/* webpackChunkName: "offers" */ '../views/Offers'),
  //   beforeEnter: roleGuard,
  //   meta: { allowedRoles: ['admin'] },
  // },
  {
    path: '/inventory-items',
    name: 'inventory-items',
    component: () => import(/* webpackChunkName: "inventory-items" */ '../views/InventoryItems'),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
  },
  {
    path: '/order-part-materials',
    name: 'order-part-materials',
    component: () =>
      import(/* webpackChunkName: "order-part-materials" */ '../views/OrderPartMaterials'),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
  },
  {
    path: '/inventory-item-dispatches',
    name: 'inventory-item-dispatches',
    component: () =>
      import(
        /* webpackChunkName: "inventory-items-dispatches" */ '../views/InventoryItemDispatches'
      ),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
  },
  {
    path: '/workshop-login',
    name: 'workshop-login',
    component: () => import(/* webpackChunkName: "workshop-login" */ '../views/worker/WorkerLogin'),
    beforeEnter: loginGuard,
  },
  {
    path: '/workshop-order-part/:id',
    name: 'worker-order-part',
    component: () =>
      import(/* webpackChunkName: "worker-order-part" */ '../views/worker/WorkerOrderPart'),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['worker'] },
  },
  {
    path: '/quick-auth/:userId/:hash',
    name: 'quick-auth',
    component: () => import(/* webpackChunkName: "quick-auth" */ '../views/QuickAuth'),
  },
  {
    path: '/order-parts/:id',
    name: 'order-part',
    component: () => import(/* webpackChunkName: "order-part" */ '../views/OrderPart'),
  },
  {
    path: '/offer-parts/:id',
    name: 'offer-part',
    component: () => import(/* webpackChunkName: "offer-part" */ '../views/OfferPart'),
  },
  {
    path: '/document-library',
    name: 'document-library',
    component: () => import(/* webpackChunkName: "document-library" */ '../views/DocumentLibrary'),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
  },
  {
    path: '/machineries-print',
    name: 'machineries-print',
    component: () =>
      import(/* webpackChunkName: "machineries-print" */ '../views/MachineriesPrint'),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
  },
  // {
  //   path: '/tasks',
  //   name: 'tasks',
  //   component: () => import(/* webpackChunkName: "tasks" */ '../views/tasks/Tasks'),
  //   beforeEnter: roleGuard,
  //   meta: { allowedRoles: ['admin'] },
  //   children: [
  //     {
  //       path: 'create',
  //       name: 'createTask',
  //       component: () => import(/* webpackChunkName: "createTask" */ '@/views/tasks/CreateTask'),
  //       beforeEnter: roleGuard,
  //       meta: { allowedRoles: ['admin'] },
  //     },
  //     {
  //       path: ':id/edit',
  //       name: 'editTask',
  //       component: () => import(/* webpackChunkName: "editTask" */ '@/views/tasks/EditTask'),
  //       beforeEnter: roleGuard,
  //       meta: { allowedRoles: ['admin'] },
  //     },
  //   ],
  // },
  // {
  //   path: '/sales/:tab',
  //   name: 'salesStatistics',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "salesStatistics" */ '../views/sales-statistics/SalesStatisticsTabs'
  //     ),
  //   beforeEnter: roleGuard,
  //   meta: { allowedRoles: ['admin'] },
  // },
  // {
  //   path: '/sales',
  //   redirect: '/sales/by-client',
  // },
  {
    path: '/public-supplier-inquiries/:hash',
    name: 'publicSupplierInquiry',
    component: () =>
      import(/* webpackChunkName: "publicSupplierInquiry" */ '../views/PublicSupplierInquiry'),
  },
  {
    path: '/supplier-portal',
    name: 'supplierPortalIndex',
    component: () =>
      import(
        /* webpackChunkName: "supplierPortalIndex" */ '../views/supplier-portal/SupplierPortalIndex'
      ),
  },
  ...invoiceRoutes,
];

const router = new VueRouter({
  mode: process.env.VUE_APP_HTML_HISTORY_MODE ? 'history' : '',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // fires first, before route guards
  if (!store.getters.accessToken) {
    await store.dispatch('tryAutoLogin');
  }
  next();
});

export default router;

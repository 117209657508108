// settings
export const SET_SETTINGS = 'setSettings';
export const UPDATE_SETTING = 'updateSetting';
export const UPDATE_LOCALE = 'updateLocale';
export const SET_TOUCH_DEVICE = 'setTouchDevice';

// measurements
export const SET_MEASUREMENTS = 'setMeasurements';
export const OPEN_MEASUREMENT_FORM = 'openMeasurementForm';
export const CLOSE_MEASUREMENT_FORM = 'closeMeasurementForm';
export const STORE_MEASUREMENT = 'storeMeasurement';
export const UPDATE_MEASUREMENT = 'updateMeasurement';
export const REMOVE_MEASUREMENT = 'removeMeasurement';

// warehouse locations
export const SET_WAREHOUSE_LOCATIONS = 'setWarehouseLocations';

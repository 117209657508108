<template>
  <v-dialog v-model="isOpen" max-width="300" transition="slide-y-reverse-transition" persistent>
    <v-card>
      <v-card-text class="d-flex pt-4 align-center justify-center">
        <v-progress-circular color="primary" class="mr-4" size="24" width="3" indeterminate />
        <h2 class="title">{{ $t('network_connection_issues') }}.</h2>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NoConnectionNotifier',

  data() {
    return {
      isOpen: false,
      fetchIntervalId: null,
    };
  },

  async mounted() {
    await this.checkConnection();
    this.fetchIntervalId = setInterval(this.checkConnection, 10000);
  },

  beforeDestroy() {
    clearInterval(this.fetchIntervalId);
  },

  methods: {
    async checkConnection() {
      try {
        const url = `${
          process.env.VUE_APP_PUBLIC_PATH
        }metadata.json?no-cache=${new Date().getTime()}`;
        await axios.get(url);
        this.isOpen = false;
      } catch (e) {
        if (e?.response?.status === 404) {
          return;
        }
        this.isOpen = true;
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <button class="sentry-test-button" @click="throwDummyError">
    Test Sentry
  </button>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SentryTester',

  props: {
    exampleStringProp: {
      type: String,
      default: 'Default example prop value',
    },
    exampleObjectProp: {
      type: Object,
      default: () => ({
        stringProperty: 'String value',
        objectProperty: {
          nestedStringProperty: 'Value',
          nestedArrayProperty: [1, 2, 3],
        },
      }),
    },
  },

  computed: {
    ...mapState(['currentUser']),
  },

  data() {
    return {
      exampleStringData: 'Default example data value',
      exampleObjectData: {
        stringProperty: 'String value',
        objectProperty: {
          nestedStringProperty: 'Value',
          nestedArrayProperty: [1, 2, 3],
        },
      },
    };
  },

  methods: {
    throwDummyError() {
      const object = {};
      console.log(object.property.property);
    },
  },
};
</script>

<style scoped>
.sentry-test-button {
  position: fixed;
  bottom: 4px;
  right: 4px;
  color: lightgray;
  font-size: 10px;
}
</style>

import http from './http';

const endpoint = 'api/order-part-process-measurements';

const measurementService = {
  model: 'measurement',

  getPage: (page, params) => http.get(`${endpoint}?page=${page}`, { params }),

  create: measurement => http.post(`${endpoint}`, measurement),

  update: measurement => http.put(`${endpoint}/${measurement.id}`, measurement),

  // accepts only measurement values
  patch: measurement => http.patch(`${endpoint}/${measurement.id}`, measurement),

  delete: measurement => http.delete(`${endpoint}/${measurement.id}`, measurement),
};

export default measurementService;
